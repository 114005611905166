
import React from "react";
import TuitList from "../tuits/tuits-list";

import WhatsHappening from "./whats-happening";

function HomeComponent() {
  return (
<>
<h1>Home</h1>
<WhatsHappening/>
<TuitList/>
</>

  );
}

export default HomeComponent