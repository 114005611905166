import React from "react";
import Nav from "../../nav";
import ReduxExamples from "./redux-examples/index";

const Assignment7 = () => {
    return (
        <>
            <Nav/>
            <h1>Assignment 7</h1>
            <ReduxExamples/>
        </>
    );
};
export default Assignment7;